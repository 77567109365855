import React, { useState, useEffect } from "react";
import Link from "next/link";
import dayjs from "dayjs";
import ReactTooltip from "react-tooltip";
import Image from "next/image";

import styles from "styles/pages/Home.module.scss";

//Component Imports
import Card, { ReactionIcons } from "components/global/Card";

import {
    processBannerImage
} from "helpers/functions";

type Props = {
    articles: any;
};


const FeaturedCards = ({ articles }: Props) => {
	console.log('articles', articles);
    return (
        <section id={'featured-cards'} className={`bottom-cards ${styles.featuredCards}`}>
            <div className={`${styles.wrapper} wrapper`}>
                {articles.featured_articles
                    .slice(1, 4)
                    .map((post: any, index: any) => {
                        // console.log('featured post', post);
                        let topics: any = post.categories ? post.categories : [];
                        if (topics && topics.length === 0 && post.legacy_topic) {
                            topics = [post.legacy_topic];
                        }

                        // set banner image
                        let image: any = post.banner ? processBannerImage(post.banner) : `/images/null-placeholder.png`;

                        if (post.card_thumbnail) {
                            image = processBannerImage(post.card_thumbnail);
                        }

                        return (
                            <Card
                                hit={post}
                                key={`featured-card-${index}`}
                                article_id={
                                    post.article_id
                                }
                                title={
                                    post.title
                                }
                                country={
                                    post.market ||
                                    null
                                }
                                badges={post.badges}
                                date={
                                    post.publishedAt
                                }
                                slug={post.slug}
                                viewCount={
                                    post.views
                                }
                                short_description={null}
                                // Unique to the user
                                hasRead={
                                    post.user_viewed
                                }
                                image={{
                                    alt: "ALT_TEXT_HERE",
                                    src: image,
                                }}
                                displayLong={true}
                                displayTruncated={true}
                                additionalClasses={styles.featuredCard}
                                hasBookmarked={
                                    post.user_bookmarked
                                }
                                reactions={
                                    post.reactions
                                }
                                topics={topics}
                                object_type={post.object_type ? post.object_type : post.type}
                                custom_type={post.card_type ? post.card_type : null}
                            />
                        )
                    })}
            </div>
        </section>
    )
}

export default FeaturedCards;