import React, { useState, useEffect } from "react";
import Link from "next/link";
import Card, { ReactionIcons } from "components/global/Card";

// Algolia Search
import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
} from "react-instantsearch-dom";

import {
    fetchUserPreferences,
    fetchTopic,
    fetchRecForMeSearch,
    checkReadStatus,
    processBannerImage
} from "helpers/functions";

// Interfaces
import {
    ApiResponse,
    ifacePreviewArticles,
    ifaceHomepageLayout, IfaceAllNewestResponse,
} from "helpers/interfaces";

// Images
import IconDropdown from "images/ui-select-down.svg";

import styles from "styles/pages/Home.module.scss";

// Algolia Search Variables
const algolia_app_id: string = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string;
const algolia_api_key: string = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string;
const algolia_articles = process.env.NEXT_PUBLIC_ALGOLIA_CONTENT;
const searchClient = algoliasearch(algolia_app_id, algolia_api_key);

const RecFilters = (props: any) => {
    const [selectAllActive, setSelectAllActive] = useState<boolean>(true);
    const [deselectAllActive, setDeselectAllActive] = useState<boolean>(true);
    const setRecFilterOpen = props.setRecFilterOpen;
    const recFilterOpen = props.recFilterOpen;
    let activeFilters = props.activeUserPrefs;
    const setActiveFilters = props.setActiveUserPrefs;
    const setRecPage = props.setRecPage;
    const [hasFilters, setHasFilters] = useState<boolean>(false);
    const [userPreferences, setUserPreferences] = useState<any>({});

    const getUserPrefs = async () => {
        const newestPostsApi: IfaceAllNewestResponse = await fetchUserPreferences();
        const prefData: any = newestPostsApi.res.data;
        if (userPreferences !== prefData) {
            setUserPreferences(prefData);
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getUserPrefs(); // get initial data
        }
        return () => {
            isMounted = false;
        };
    }, []);

    function updateActivePrefs(container: any) {
        if (recFilterOpen) {
            let updatedOptions: any = {
                market: [],
                topics: [],
                stamps: []
            };
            const marketOptions = container.querySelectorAll('.market-filter-option');
            marketOptions.forEach((option: any) => {
                let checked = option.querySelector('input[type=checkbox]').checked;
                let val = option.querySelector('.ais-RefinementList-labelText').innerHTML;
                if (checked && updatedOptions.market.indexOf(val) === -1) {
                    updatedOptions.market.push(val);
                }
            });

            const stampOptions = container.querySelectorAll('.stamp-filter-option');
            stampOptions.forEach((option: any) => {
                let checked = option.querySelector('input[type=checkbox]').checked;
                let val = option.querySelector('.ais-RefinementList-labelText').innerHTML;
                if (checked && updatedOptions.stamps.indexOf(val) === -1) {
                    updatedOptions.stamps.push(val);
                }
            });

            const topicOptions = container.querySelectorAll('.topic-filter-option');
            topicOptions.forEach((option: any) => {
                let checked = option.querySelector('input[type=checkbox]').checked;
                let val = option.querySelector('.ais-RefinementList-labelText').innerHTML;
                if (checked && updatedOptions.topics.indexOf(val) === -1) {
                    updatedOptions.topics.push(val);
                }
            });
            setActiveFilters(updatedOptions);
            setRecFilterOpen(false);
        }
    }

    document.body.addEventListener('click', (e) => {
        const container = document.getElementById('rec-filters-container');
        if (container !== null && (container !== e.target && !container.contains(e.target as Node))) {
            // updateActivePrefs(container);
            setRecFilterOpen(false);
        }
    }, true);

    function toggleAll({ toggle }: any) {
        const filterOptions = Array.from(document.getElementsByClassName('filter-checkbox'));

        if (filterOptions.length > 0) {
            filterOptions.forEach((el: any) => {
                if ((toggle === 'on' && !el.checked) || (toggle === 'off' && el.checked)) {
                    el.click();
                }
            })
        }
    }

    return (
        <div id={'rec-filters-container'} className={`filters-container ${recFilterOpen && 'show'}`}>
            <div className={'top-row'}>
                <p>Filter By</p>
                <div className={'select-options'}>
                    <button className={`${!selectAllActive && 'disabled'}`} onClick={() => {
                        setSelectAllActive(false);
                        setDeselectAllActive(true);
                        toggleAll({ toggle: 'on' });
                    }}>Select All
                    </button>
                    <button className={`${!deselectAllActive && 'disabled'}`} onClick={() => {
                        setDeselectAllActive(false);
                        setSelectAllActive(true);
                        toggleAll({ toggle: 'off' });
                    }}>Deselect All
                    </button>
                </div>
            </div>
            <div className={'preferences-container'}>
                <ul className={'ais-RefinementList-list'}>
                    {userPreferences && userPreferences.queries && userPreferences.queries.length > 0
                        && userPreferences.queries.map((query: any) => {
                            !hasFilters && setHasFilters(true);
                            return <CustomRefinementItem filter={query} filterType={'queries'} activeFilters={activeFilters}
                                setActiveFilters={setActiveFilters} key={query} setRecPage={setRecPage} />
                        })}

                    {!hasFilters && (
                        <li><p className={'no-filters-message'}><span
                            className={'bold'}>Customize your preferences</span> to follow specific markets, topics, or
                            classifications</p></li>
                    )}
                </ul>
            </div>
        </div>
    )
}

const CustomRefinementItem = (props: any) => {
    const filter: any = props.filter;
    const filterType: any = props.filterType;
    let activeFilters: any = props.activeFilters;
    const setActiveFilters: any = props.setActiveFilters;
    const setRecPage: any = props.setRecPage;

    const [topicId, setTopicId] = useState<number>(filterType === 'topics' ? -1 : 0);
    const [checked, setChecked] = useState<boolean>(activeFilters && activeFilters[filterType] !== null && activeFilters[filterType] !== undefined ? activeFilters[filterType].indexOf(filter) > -1 : true);

    let filterLabel: string = 'Search Term';
    let attribute: string = 'query';
    if (filterType === 'market') {
        filterLabel = 'Market';
        attribute = 'country';
    } else if (filterType === 'stamps') {
        filterLabel = 'Classification';
        attribute = 'stamps.' + filter.trim().toLowerCase().replace(/\s/g, "-");
    } else if (filterType === 'topics') {
        filterLabel = 'Topic';
        attribute = 'category_id';
    }

    const slug = filter.trim().toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');

    const getTopicData = async () => {
        const topicApi: IfaceAllNewestResponse = await fetchTopic(slug);
        if (topicApi.loaded && !topicApi.error) {
            const topicData: any = topicApi.res.data;
            setTopicId(topicData.id);
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted && filterType === 'topics') {
            getTopicData();
        }
        return () => {
            isMounted = false;
        };
    }, []);

    let defaultRefinement = [];
    if (checked) {
        if (filterType === 'topics') {
            const topicString: any = topicId.toString();
            defaultRefinement.push(topicString);
        } else if (filterType === 'stamps') {
            defaultRefinement.push(true);
        } else {
            defaultRefinement.push(filter);
        }
    }

    function updateActiveFilters(e: any) {
        let updatedFilters = { ...activeFilters };
        let active = e.target.checked;
        let currentActiveFilters = updatedFilters[filterType];
        if (currentActiveFilters === null) {
            currentActiveFilters = [filter];
        } else if (active && currentActiveFilters.indexOf(filter) === -1) {
            currentActiveFilters.push(filter);
        } else if (!active && currentActiveFilters.indexOf(filter) > -1) {
            let index = currentActiveFilters.indexOf(filter);
            currentActiveFilters.splice(index, 1);
        }
        updatedFilters[filterType] = currentActiveFilters;
        setActiveFilters(updatedFilters);
        setChecked(active);
    }

    if (topicId !== -1) {
        return (
            <li className={'stamp-filter-option'}>
                <label>
                    <div>
                        <span className={'ais-RefinementList-labelText'}>{filter}</span>
                    </div>
                    <input type={'checkbox'} value={filter}
                        onChange={(e) => {
                            updateActiveFilters(e);
                            setRecPage(1);
                        }} className={'filter-checkbox'} checked={checked} />
                </label>
            </li>
        )
    } else {
        return <></>
    }
}

const Rec4MeSearch = (props: any) => {

    const [articles, setArticles] = useState<any>(null);
    const [visibleIndex, setVisibleIndex] = useState<number>(0);
    const [meta, setMeta] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(props.recPage);
    const [hasActiveFilters, setHasActiveFilters] = useState<boolean>(false);
    const allUserInterest: any = props.userPreferences ? props.userPreferences : null;
    const preferences: any = props.activeUserPrefs ? props.activeUserPrefs : null;

    const checkActiveFilters = () => {
        let updatedActive: boolean = false;
        for (let key in preferences) {
            if (key !== 'user_id') {
                if (preferences[key] && preferences[key].length > 0) {
                    updatedActive = true;
                }
            }
        }
        if (hasActiveFilters !== updatedActive) {
            setHasActiveFilters(updatedActive);
            if (!updatedActive) {
                setArticles(null);
                setMeta(null);
            }
        }
    }

    useEffect(() => {
        if (preferences) {
            checkActiveFilters();
        }
    }, [preferences]);

    useEffect(() => {
        if (visibleIndex > 0) {
            setVisibleIndex(0);
        }
    }, [articles]);

    const [init, setInit] = useState<boolean>(true);

    const getData = async () => {
        let promises: any = [];
        for (let n = 1; n <= props.recPage; n++) {
            const results = await fetchRecForMeSearch(init, preferences ? preferences.queries : null);

            if (results.loaded && !results.error) {
                if (results.res && results.res.length > 0 && typeof results.res[0] !== 'string') {
                    let articlesArr: any = [...results.res];
                    articlesArr = articlesArr.filter((article: any) => {
                        let show: boolean = true;
                        if (props.unreadOnly && article.viewed === true) {
                            show = false;
                        }

                        return show;
                    })
                    setArticles(articlesArr);
                } else {
                    setArticles(null);
                }
                if (init) {
                    setInit(false);
                }
            } else {
                setArticles(null);
            }
        }
    };

    useEffect(() => {
        if (hasActiveFilters && preferences && Object.keys(preferences).length > 0) {
            getData();
        }
    }, [preferences, props.unreadOnly, props.recPage, hasActiveFilters]);

    // useEffect(() => {
    //     console.log('rec4me articles', articles);
    // }, [articles]);

    const updatePageNum = () => {
        if (currentPage !== props.recPage) {
            setCurrentPage(props.recPage);
        }
    }

    useEffect(() => {
        updatePageNum();
    }, [props.recPage]);

    // if (articles) {
    //     console.log('Rec4Me results', articles);
    // }

    return (
        <>
            {hasActiveFilters && articles && articles.length > 0 ? (
                <div className={'ais-InfiniteHits'}>
                    <ul className={'ais-InfiniteHits-list'}>
                        {articles.map((hit: any, index: number) => {
                            let show: boolean = true;
                            if (props.unreadOnly && hit.viewed === true) {
                                show = false;
                            }

                            if (show) {
                                return (
                                    <li className={`ais-InfiniteHits-item ${index < 4 + (4 * visibleIndex) ? '' : styles.hidden}`} key={'rec4me-list-item-' + index}>
                                        <Hit hit={hit} index={index} key={'rec4me-hit-' + hit.id} />
                                    </li>
                                )
                            } else {
                                return <></>;
                            }

                        })}
                    </ul>
                    {articles && articles.length > 4 && articles.length > (4 + (4 * visibleIndex)) && (
                        <div className={`buttons-container show ${styles.buttonsContainer}`}>
                            <button className={'btn'} onClick={(e) => {
                                e.preventDefault();
                                setVisibleIndex(visibleIndex + 1);
                            }}>Load More</button>
                        </div>
                    )}
                </div>
            ) : (
                <NoResultsMessage />
            )}
        </>
    )
}

const NoResultsMessage = () => {
    return (
        <div className={'no-results-msg'}>There are no results for your selected filters. Adjust your
            filters to see your recommended content.
        </div>
    )
}

type Props = {
    activeUserPrefs?: any,
    setActiveUserPrefs?: any,
    userPreferences?: any,
    hasFilters?: any,
    setHasFilters?: any
};

const Rec4Me = ({ userPreferences, activeUserPrefs, setActiveUserPrefs, hasFilters, setHasFilters }: Props) => {
    //Recommended Section
    const [recFilterOpen, setRecFilterOpen] = useState(false);
    const [recPage, setRecPage] = useState<number>(1);
    const [unreadOnly, setUnreadOnly] = useState<boolean>(true);

    return (
        <section id={'recommended'} className={`recommended articles-container ${styles.recommended}`}>
            <div className={'wrapper'}>
                <div className="title">
                    <h2 className="h1">For Me</h2>
                </div>
                <div className={'options'}>
                    <div className={'dropdown-options'}>
                        <button className={'toggle-dropdown'} onClick={() => {
                            setRecFilterOpen(true);
                        }}>
                            <span>Filter By</span>
                            <IconDropdown />
                        </button>
                        <RecFilters
                            setRecFilterOpen={setRecFilterOpen}
                            userPreferences={userPreferences}
                            recFilterOpen={recFilterOpen}
                            activeUserPrefs={activeUserPrefs}
                            setActiveUserPrefs={setActiveUserPrefs}
                            setRecPage={setRecPage}
                        />
                    </div>
                    <div className={'checkbox-option'}>
                        <input id={'unread-only'} type={'checkbox'} name={'unread-only'}
                            value={'unread-only'} className={'unread-only'}
                            onChange={(e) => {
                                setUnreadOnly(e.target.checked);
                                setRecPage(1);
                            }} checked={unreadOnly} />
                        <label htmlFor={'unread-only'}>Unread Only</label>
                    </div>
                    <Link href={{ pathname: "profile/interests" }}>Customize Interests</Link>
                </div>
                <Rec4MeSearch
                    userPreferences={userPreferences}
                    activeUserPrefs={activeUserPrefs}
                    unreadOnly={unreadOnly}
                    recPage={recPage}
                    setRecPage={setRecPage}
                />
                {!hasFilters && (
                    <div className={'no-results-msg'}>
                        <p>You haven't created any interests yet! <a href={'/profile'}>Customize
                            your interests</a> on the profile page, or use the Follow button across the site.</p>
                    </div>
                )}
            </div>
        </section>
    )
}

export default Rec4Me;

const Hit = (props: any, index: any) => {
    // console.log("Rec4Me hit", props.hit);

    const [post, setPost] = useState<any>(props.hit);
    const [withUserData, setWithUserData] = useState<boolean>(false);
    const getData = async () => {
        const pageData = await checkReadStatus(post.id);
        if (pageData.loaded && !pageData.error && pageData.res.data.length > 0) {
            setPost(pageData.res.data[0]);
            if (!withUserData) {
                setWithUserData(true);
            }
        }
    };

    let datePublished: any = new Date();
    datePublished = post.year ? post.year : post.published_at * 1000;
    // datePublished = new Date(datePublished.getTime() + Math.abs(datePublished.getTimezoneOffset() * 60000));

    let topics: any = post.topic ? post.topic : [];
    if (topics && topics.length === 0 && post.legacy_topic) {
        topics = [post.legacy_topic];
    }

    if (post.additional_topics && post.additional_topics.length > 0) {
        props.hit.additional_topics.map((item: any) => {
            if (Array.isArray(item)) {
                item.map((u: any) => {
                    if (Array.isArray(u)) {
                        u.map((n: any) => {
                            if (n) {
                                if(topics.indexOf(n) === -1) {
                                    topics.push(n);
                                }
                            }
                        })
                    } else {
                        if(topics.indexOf(u) === -1) {
                            topics.push(u);
                        }
                    }
                })
            } else {
                if(topics.indexOf(item) === -1) {
                    topics.push(item);
                }
            }
        })
    }

    if (post.object_type === 'stravito' || post.object_type === 'global_food_marketing') {
        topics = null;
    }

    if (post.category) {
        topics = post.category;
    }

    let image: any = `/images/null-placeholder.png`;
    if (post.banner) {
        image = post.banner.includes('https://') ? post.banner : processBannerImage(post.banner, true);
    } else if (post.banner_image) {
        image = processBannerImage(post.banner_image, true);
    } else if (post.card_thumbnail) {
        image = processBannerImage(post.card_thumbnail, true);
    }

    if(post.object_type === 'custom_card') {
        console.log(post);
        console.log(image);
    }

    let description: any = post.short_description ? post.short_description : ``;

    if (post.post_introduction) {
        description = post.post_introduction;
    }

    if (post.takeaway_text) {
        description = post.takeaway_text;
    }

    if(post.card_text) {
        description = post.card_text;
    }

    return (
        <Card
            hit={post}
            key={post.slug}
            article_id={post.article_id}
            title={post.title}
            badges={post.badges}
            country={post.market ? post.market : post.country}
            date={datePublished}
            year={post.year}
            slug={post.slug}
            viewCount={post.views}
            hasBookmarked={post.user_bookmarked}
            image={{
                alt: "ALT_TEXT_HERE",
                src: image,
            }}
            short_description={description}
            hasRead={post.viewed} // cannot actually mark if it's been read here
            hideBookmarkIcon={false}
            displayLong={true}
            reactions={post.reactions}
            topics={topics}
            object_type={post.object_type ? post.object_type : post.type}
            fileUrl={post.url ? post.url : null}
            custom_type={post.card_type ? post.card_type : null}
        />
    );
}