import React, { useState, useEffect, useRef } from "react";
import Card, { ReactionIcons } from "components/global/Card";
import Image from "next/image";
import Link from "next/link";

import NavArrow from "images/ui-swiper-arrow.svg";
import Pause from "images/ui-pause.svg";
import Play from "images/ui-play.svg";

// Styles
import styles from "styles/pages/Home.module.scss";

// Import Swiper React components
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay
} from 'swiper/modules';


import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { fetchMetaData, processBannerImage, fetchData } from "helpers/functions";

type Props = {
    articles: any;
    width: any
};

const TrendingSlide = (params: any) => {
    const post: any = params.post;
    const index: any = params.index;

    let topics: any = post.topic ? post.topic : [];
    if (topics && topics.length === 0 && post.legacy_topic) {
        topics = [post.legacy_topic];
    }
    if ((post.type === 'customCard' || post.type === 'custom_card') && post.categories) {
        topics = post.categories;
    }

    const [metaData, setMetaData] = useState<any>(null);
    async function getMetaData() {
        if (post.type !== 'customCard' && post.type !== 'custom_card') {
            let params: any = {}
            const key: string = post.type + 's';
            const idArr: any = post.id.split('-');
            params[key] = [idArr[1]];
            const pageMetaData: any = await fetchMetaData(params);
            if (!pageMetaData.error && pageMetaData.res && pageMetaData.res.data && pageMetaData.res.data.length > 0) {
                const res: any = pageMetaData.res.data[0];
                setMetaData(res);
            }
        }
    }

    useEffect(() => {
        if (!metaData) {
            getMetaData();
        }
    }, []);

    // console.log('trending slide:', post);

    return (
        <Card
            hit={post}
            key={post.slug}
            article_id={metaData ? metaData.id : post.id.split('-')[1]}
            title={post.title}
            country={
                post.market || null
            }
            badges={post.badges ? JSON.parse(post.badges) : null}
            date={post.publishedAt}
            slug={post.slug}
            viewCount={metaData ? metaData.views : post.views}
            hasBookmarked={metaData ? metaData.user_bookmarked : false}
            // NEED IMAGE
            image={{
                alt: "alt txt",
                src:
                    processBannerImage(post.banner) || '/images/null-placeholder.png',
            }}
            isFeatured={post.is_featured}
            // Need Has Read
            hasRead={metaData ? metaData.user_viewed : false}
            short_description={
                post.short_description
                    ? post.short_description
                    : null
            }
            reactions={post.reactions}
            displayLong={true}
            additionalClasses={`trendingCards ${styles.trendingCards}`}
            topics={topics}
            object_type={post.object_type ? post.object_type : post.type}
            trendingNumber={index}
            custom_type={post.card_type ? post.card_type : null}
        />
    )
}

const Trending = ({ articles, width }: Props) => {
    // SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Thumbs]);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const pagRef = useRef(null);
    const [trendSwiper, setTrendSwiper] = useState<any>(null);
    const [paused, setPaused] = useState<boolean>(false);

    function togglePause() {
        paused ? setPaused(false) : setPaused(true);
    }

    return (
        <section id={'trending'} className={`trending ${styles.trending}`}>
            <div className={'wrapper'}>
                <div className={'trending__inner'}>
                    <div className="title">
                        <h2 className={`h1`}>
                            Trending Now
                        </h2>
                    </div>
                    <div className="cards">
                        <div className={'swiper-row'}>
                            {width >= 768 && (
                                <button ref={prevRef} className={'swiper-button-prev'}>
                                    <span>Previous Slide</span>
                                    <NavArrow />
                                </button>
                            )}
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current,
                                }}
                                pagination={{
                                    el: pagRef.current,
                                    clickable: true,
                                }}
                                spaceBetween={0}
                                slidesPerView={1}
                                // autoHeight={true}
                                loop={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                onSwiper={setTrendSwiper}
                            >
                                {articles
                                    .slice(0, 5)
                                    .map((post: any, index: any) => {
                                        // console.log('trending post:', post);
                                        return (
                                            <SwiperSlide key={`trending-slide-${index}`}>
                                                <TrendingSlide
                                                    post={post}
                                                    index={index}
                                                />
                                            </SwiperSlide>
                                        )
                                    })}
                            </Swiper>
                            {width >= 768 && (
                                <button ref={nextRef} className={'swiper-button-next'}>
                                    <span>Next Slide</span>
                                    <NavArrow />
                                </button>
                            )}
                        </div>
                        <div className={'swiper-navigation-container'}>
                            {width < 768 && (
                                <button ref={prevRef} className={'swiper-button-prev'}>
                                    <span>Previous Slide</span>
                                    <NavArrow />
                                </button>
                            )}
                            <div className={'pagination-container'}>
                                <div ref={pagRef}></div>
                                <button className={'pause-button'} onClick={(e: any) => {
                                    e.preventDefault();
                                    if (trendSwiper) {
                                        if (paused) {
                                            trendSwiper.autoplay.start();
                                        } else {
                                            trendSwiper.autoplay.stop();
                                        }
                                    }
                                    togglePause();
                                }}>
                                    {paused ? (
                                        <>
                                            <Play />
                                            <span>Play</span>
                                        </>
                                    ) : (
                                        <>
                                            <Pause />
                                            <span>Paused</span>
                                        </>
                                    )}
                                </button>
                            </div>
                            {width < 768 && (
                                <button ref={nextRef} className={'swiper-button-next'}>
                                    <span>Next Slide</span>
                                    <NavArrow />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Trending;