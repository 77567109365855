import React, { useState, useEffect } from "react";
// import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import Card, { ReactionIcons } from "components/global/Card";
import BookmarkButton from "../components/bookmarks/BookmarkButton";
import Rec4Me from "components/homepage/Rec4Me";
import Trending from "components/homepage/Trending";
import Latest from "components/homepage/Latest";
import FeaturedBanner from "components/homepage/FeaturedBanner";
import FeaturedCards from "components/homepage/FeaturedCards";
import PreFooter from "components/homepage/PreFooter";

import cardStyles from "styles/components/Card.module.scss";
import {
  fetchBookmarksFolders,
  fetchData,
  fetchTopic,
  updateUserPreferences,
  fetchUserPreferences,
  fetchTopics,
  toTimestamp,
  checkReadStatus,
  fetchRecForMeSearch,
  processBannerImage
} from "helpers/functions";
// import { GetServerSideProps } from "next";
// import axios from "axios";
import dayjs from "dayjs";

// Algolia Search
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Configure,
  InfiniteHits,
  connectInfiniteHits,
  connectStateResults,
  RangeInput
} from "react-instantsearch-dom";

// Global State
import { useRecoilState } from "recoil";
import { navbarColorState, isFGDUserState, pageLoadedState, currentUserState, topicListState } from "state/atoms";

// Layout
import Layout from "components/global/Layout";

// Images for inline SVG
import IconViews from "images/icon-views.svg";
import IconBookmark from "images/icon-bookmark.svg";
import ReactionThumbsup from "images/reaction-thumbsup.svg";
import IconDropdown from "images/ui-select-down.svg";
import Globe from "images/icon-globe.svg";
import Folder from "images/icon-folder.svg";
import Fade from "images/trending-fade.svg";

// Interfaces
import {
  ApiResponse,
  ifacePreviewArticles,
  ifaceHomepageLayout, IfaceAllNewestResponse,
} from "helpers/interfaces";
import orderBy from "lodash/orderBy";
import SearchCard from "../components/search/SearchCard";

type Props = {
  previewArticles: null | ifacePreviewArticles;
};

// Algolia Search Variables
const algolia_app_id: string = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string;
const algolia_api_key: string = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string;
const algolia_articles = process.env.NEXT_PUBLIC_ALGOLIA_CONTENT;
const searchClient = algoliasearch(algolia_app_id, algolia_api_key);

declare const window: any;

const NoResultsMessage = () => {
  return (
    <div className={'no-results-msg'}>There are no results for your selected filters. Adjust your
      filters to see your recommended content.
    </div>
  )
}

export default function Home({ previewArticles = null }: Props) {
  // State Setup for this page
  const [navbarColor, setNavbarColor] = useRecoilState(navbarColorState);
  // const [isLoggedin, setIsLoggedin] = useRecoilState(loginState);

  const [showFeatured, setShowFeatured] = useState(false);

  // Unique testing images
  let startingImage: number = 59;

  function testImage() {
    startingImage++;
    // return `https://picsum.photos/id/${startingImage}/1200/600`;
    return `/images/null-placeholder.png`;
  }

  // Set Nav color for this page - function is passed down as prop
  useEffect(() => {
    setNavbarColor("light");
  }, [showFeatured]);

  // Use state for current window width
  const [width, setWidth] = useState(0);

  // When component renders or window is resized, grab new window width
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    // Get initial data
    getData();
    return () => {
      window.removeEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    };
  }, [previewArticles]);

  const startingState: ApiResponse = {
    loaded: false,
    error: false,
    res: {
      data: [],
    },
  };

  const [featuredPosts, setFeaturedPosts] = useState(startingState);
  const [topFeatured, setTopFeatured] = useState(startingState);
  const [trendingPosts, setTrendingPosts] = useState<any>(startingState);
  const [newestPosts, setNewestPosts] = useState(startingState);
  // * To capture API to state for future use!
  const [homepageLayout, setHomepageLayout] = useState(
    null as null | ifaceHomepageLayout
  );

  const [articles, setArticles] = useState({
    featured_articles: [],
    newest_articles: [],
    trending_articles: [],
    pre_footer: null
  } as ifacePreviewArticles);

  // const [pageLoad, setPageLoad] = useRecoilState(pageLoadState);

  const getData = async () => {
    const featuredPostsApi: ApiResponse = await fetchData(
      `homepage-layout/featured`
    );

    const trendingPostsApi: ApiResponse = await fetchData(
      `homepage-layout/trending`
    );
    const newestPostsApi: ApiResponse = await fetchData(
      `articles/newest`
    );

    const homepageLayoutApi: ifaceHomepageLayout = await fetchData(
      "settings/homepage-layout"
    );

    setFeaturedPosts(featuredPostsApi);
    setTrendingPosts(trendingPostsApi);
    setNewestPosts(newestPostsApi);
    // Set homepage layout.
    if (homepageLayoutApi.loaded && !homepageLayoutApi.error) {
      setHomepageLayout(homepageLayoutApi); // Currently unused
      // setShowFeatured(homepageLayoutApi.res.featured_active); // Handles Homepage Layout
    }

    if (
      window.location.pathname === "/homepage-preview" &&
      previewArticles
    ) {
      setArticles({ ...previewArticles });
    } else if (window.location.pathname !== "/homepage-preview") {
      const newArticles = {
        featured_articles: featuredPostsApi.res.data,
        newest_articles: newestPostsApi.res.data,
        trending_articles: trendingPostsApi.res.data,
        pre_footer: null
      };
      setArticles({ ...newArticles });
    }
  };

  useEffect(() => {
    if (
      featuredPosts.loaded &&
      featuredPosts.res.data !== undefined &&
      featuredPosts.res.data.length > 0
    ) {
      setShowFeatured(true);
    } else {
      setShowFeatured(false);
    }
  }, [newestPosts]);

  // Logic for hiding loading screen after APIs are loaded in
  const [pageLoaded, setPageLoaded] = useRecoilState(pageLoadedState);
  useEffect(() => {
    // If page has not loaded yet, and there's *some* homepage data that's been stored in state
    if (articles && articles.featured_articles) {
      if (
        (articles.featured_articles.length ||
          articles.newest_articles.length ||
          articles.trending_articles.length) &&
        !pageLoaded
      ) {
        // Then show the page after a little buffer time
        setTimeout(() => {
          setPageLoaded(true);
        }, 125);
      }
    }
    // Check each time the articles state is updated
  }, [articles]);

  // Featured Article icons
  // TODO: Clean this up - refactor as Card component?

  // Bookmarked State
  const [isBookmarked, setIsBookmarked] = useState(false);

  // const featured = undefined;
  // const featured = featuredPosts.res.data.slice(0, 1);
  // let featured = [];
  // console.log("slice", featuredPosts.res.data.slice(0, 1));

  //Recommended Section
  const [recFilterOpen, setRecFilterOpen] = useState(false);
  const [userPreferences, setUserPreferences] = useState<any>({});
  const [activeUserPrefs, setActiveUserPrefs] = useState<any>({});
  const [filterString, setFilterString] = useState<any>(null);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [recPage, setRecPage] = useState<number>(1);
  const [unreadOnly, setUnreadOnly] = useState<boolean>(true);
  let prevYear: any = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  // prevYear = ((prevYear.getMonth() > 8) ? (prevYear.getMonth() + 1) : ('0' + (prevYear.getMonth() + 1))) + '/' + ((prevYear.getDate() > 9) ? prevYear.getDate() : ('0' + prevYear.getDate())) + '/' + prevYear.getFullYear();
  const endToday: any = new Date();
  endToday.setUTCHours(23, 59, 59, 999);
  const [searchDates, setSearchDates] = useState({
    start: toTimestamp(prevYear) / 1000,
    end: toTimestamp(endToday) / 1000,
  });

  const getUserPrefs = async () => {
    const newestPostsApi: IfaceAllNewestResponse = await fetchUserPreferences();
    const prefData: any = newestPostsApi.res.data;
    if (userPreferences !== prefData) {
      setUserPreferences(prefData);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getUserPrefs(); // get initial data
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (activeUserPrefs !== userPreferences) {
      setActiveUserPrefs(userPreferences);
    }
  }, [userPreferences]);

  const StateResults = ({ searchResults }: any) => {
    const [hasResults, setHasResults] = useState<boolean>(searchResults && searchResults.nbHits !== 0 || false);
    const nbHits = searchResults && searchResults.nbHits;
    let hasActiveFilters = false;
    let hasPreferences = false;

    if (activeUserPrefs) {
      for (let key in activeUserPrefs) {
        if (key !== 'user_id') {
          if (activeUserPrefs[key] && activeUserPrefs[key].length > 0) {
            hasActiveFilters = true;
          }
        }
      }
    }

    if (!hasActiveFilters && hasResults) {
      setHasResults(false);
    }

    if (userPreferences) {
      for (let key in userPreferences) {
        if (key !== 'user_id') {
          if (userPreferences[key] && !hasPreferences) {
            hasPreferences = true;
          }
        }
      }
    }

    return (
      <>
        {(hasResults || !hasPreferences) ? (
          <></>
        ) : (
          <div className={'empty-test'}>
            <NoResultsMessage />
          </div>
        )}
      </>
    );
  };

  const CustomStateResults = connectStateResults(StateResults);


  let [allTopics, setAllTopics] = useState<any>(null);

  const getTopics = async () => {
    const topicsApi: IfaceAllNewestResponse = await fetchTopics();
    if (topicsApi.loaded && !topicsApi.error) {
      setAllTopics(topicsApi.res.data);
    }
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getTopics(); // get initial data
    }
    return () => {
      isMounted = false;
    };
  }, []);

  //Check if preferences set
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  const [hasActiveFilters, setHasActiveFilters] = useState<boolean>(false);

  const checkForFilters = () => {
    for (let key in userPreferences) {
      if (key !== 'user_id') {
        if (!hasFilters && userPreferences[key] !== null && userPreferences[key].length > 0) {
          setHasFilters(true);
        }
      }
    }
  }

  useEffect(() => {
    if (userPreferences) {
      checkForFilters();
    }
  }, [userPreferences]);

  // Hide from FGD users
  const [isFGDUser, setFGDUser] = useRecoilState(isFGDUserState);

  if (isFGDUser) {
    return (
      <Layout title="Error 404: Page Not Found">
        <main className="page errorPage">
          <div className="wrapper">
            <h1>404</h1>
            <p>Sorry, the page you requested could not be found.</p>
            <a className="btn narrow" href="/design">Back to Feel Good Design Home</a>
          </div>
        </main>
      </Layout>
    )
  } else {
	if (featuredPosts) {
		return (
			<Layout title="Homepage">
				<div className="page--homepage">
					{articles.featured_articles && articles.featured_articles.length > 0 && (
						<>
						<FeaturedBanner featured={articles.featured_articles[0]} />
						{articles.featured_articles.length > 1 && (
							<FeaturedCards articles={articles} />
						)}
						</>
					)}
					{featuredPosts.loaded &&
						<Rec4Me
							userPreferences={userPreferences}
							activeUserPrefs={activeUserPrefs}
							setActiveUserPrefs={setActiveUserPrefs}
							hasFilters={hasFilters}
							setHasFilters={setHasFilters}
						/>
					}
					{featuredPosts.loaded && trendingPosts && trendingPosts.res.data && trendingPosts.res.data.length > 0 && (
						<Trending
						articles={trendingPosts.res.data}
						width={width}
						/>
					)}
					{featuredPosts.loaded &&
						<Latest
							articles={articles}
						/>
					}
					{featuredPosts.loaded &&
						<PreFooter
							preview_footer={articles && articles.pre_footer ? articles.pre_footer : null}
						/>
					}
				</div>
			</Layout>
		);
	}
	else {
		return (
			<Layout title="Homepage">
				<div className="page--homepage">
					<Rec4Me
						userPreferences={userPreferences}
						activeUserPrefs={activeUserPrefs}
						setActiveUserPrefs={setActiveUserPrefs}
						hasFilters={hasFilters}
						setHasFilters={setHasFilters}
					/>
					{trendingPosts && trendingPosts.res.data && trendingPosts.res.data.length > 0 && (
						<Trending
						articles={trendingPosts.res.data}
						width={width}
						/>
					)}
					<Latest
						articles={articles}
					/>
					<PreFooter
						preview_footer={articles && articles.pre_footer ? articles.pre_footer : null}
					/>
				</div>
			</Layout>
			);
	}
  }
}
