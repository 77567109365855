import React, { useState, useEffect } from "react";
import { fetchData } from "helpers/functions";

type Props = {
    preview_footer?: any;
};

const PreFooter = ({ preview_footer }: Props) => {
    const [preFooter, setPreFooter] = useState<any>(null);

    // Function to grab footer items client side
    async function fetchPreFooter() {
        const preFooterData = await fetchData("settings/homepage-layout-pre-footer");
        // console.log('preFooterData', preFooterData);
        if (preFooterData.loaded && !preFooterData.error) {
            setPreFooter(preFooterData.res);
        }
        // return preFooterData;
    }

    useEffect(() => {
        if(preview_footer) {
            setPreFooter(preview_footer);
            console.log('preview footer');
        } else {
            fetchPreFooter();
        }
    }, []);

    useEffect(() => {
        if(preview_footer) {
            setPreFooter(preview_footer);
            console.log('preview footer');
        }
    }, [preview_footer]);

    return (
        <>
            {preFooter && (preFooter.pre_footer_title || (preFooter.pre_footer_cta_text && preFooter.pre_footer_cta_url)) ? (
                <section id={'bottom-banner'} className={'bottom-banner'}>
                    <div className={'wrapper'}>
                        <div className={'banner-container'}>
                            {preFooter.pre_footer_title && (
                                <h2>{preFooter.pre_footer_title}</h2>
                            )}
                            {preFooter.pre_footer_cta_text && preFooter.pre_footer_cta_url && (
                                <a href={preFooter.pre_footer_cta_url} target={preFooter.pre_footer_cta_target === true || preFooter.pre_footer_cta_target === 'true' ? '_blank' : ''}>{preFooter.pre_footer_cta_text}</a>
                            )}
                        </div>
                    </div>
                </section>
            ) : (<section id={'bottom-banner'} className={'bottom-banner no-content'}></section>)}
        </>
    )
}

export default PreFooter;